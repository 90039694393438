import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;


// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.tabs';


import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import Accordions from './modules/Accordions';
import Glide from '@glidejs/glide';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		var $navMenuContainer = $('.nav-menu-container');
    var $hamburger = $('.hamburger');
    var isOpen = $navMenuContainer.hasClass('is-active');

    $hamburger.toggleClass('is-active');
    $navMenuContainer.toggleClass('is-active');
    $('.nav-main').toggleClass('menu-active');
    $('.nav-bg').toggleClass('is-active');

    $hamburger.attr('aria-expanded', isOpen ? 'false' : 'true');

    if (isOpen) {
        $('body').find('a[href], button, input, select, textarea, [tabindex]').not('.header-center *').removeAttr('tabindex');
				$('body').find('.glide__slide').not('.header-center *').attr('tabindex', '0');
    } else {
        $('body').find('a[href], button, input, select, textarea, .glide__slide').not('.header-center *').attr('tabindex', '-1');
        $navMenuContainer.find('a[href], button, input, select, textarea').first().focus();
    }
		e.preventDefault();
	});

	// submenu aria setting
	if ($(window).width() >= 1024) {
		var dropdownParent = $(".is-dropdown-submenu-parent");
		var dropdownButton = dropdownParent.find(".nav-link-submenu-btn");

		var handleClassChange = function(mutationsList, observer) {
				var isActive = dropdownParent.hasClass("is-active");
				dropdownButton.attr("aria-expanded", isActive ? "true" : "false");
		};

		var observer = new MutationObserver(handleClassChange);

		observer.observe(dropdownParent[0], { attributes: true });

		var isActive = dropdownParent.hasClass("is-active");
		dropdownButton.attr("aria-expanded", isActive ? "true" : "false");

		dropdownButton.on("click", function() {
				dropdownParent.toggleClass("is-active");
		});

		dropdownButton.on("keydown", function(event) {
				if (event.key === "Enter" || event.key === " ") {
						event.preventDefault();
						dropdownParent.toggleClass("is-active");
				}
		});
	}

	// hash scroll
	$('a[href^="#"]').on('click',function (e) {
		var target = this.hash;
		if(target != "#maincontent"){
			e.preventDefault();
			var $target = $(target);
			$('html, body').stop().animate({
				'scrollTop': $target.offset().top - 100
			}, 500, 'swing');
			$target.focus();
		}
	});	

	// load more
	$('#loadmore').on('click', function (e) {
		e.preventDefault();
		$('.role-item:hidden').slice(0, 2).slideDown();
		if ($('.role-item:hidden').length == 0) {
			$('#loadmore').addClass('hide');
		}
	});

	var clickedButton;

	// content modal open
	$('.modal-content-link').on('click', function () {
		console.log('modal-content-link');
		clickedButton = $(this);
		var contentHtml = $(this).attr('data-content-text');
		$('#modal-content-inner').html(contentHtml);
		$('#'+$(this).attr('data-content-id')).foundation('open');
	});

	// video modal open
	$('.modal-link').on('click', function () {
		//$('#video-modal').foundation('open');
		clickedButton = $(this);
		$('#'+$(this).attr('data-video-id')).foundation('open');
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
		$('.video-transcript .btn').attr("href", $(this).attr('data-video-transcript'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
		setFocusBackToButton();
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
		setFocusBackToButton();
	});
	$('.reveal-video .video-transcript .btn-primary').on('click', function (e) {
		var tempLink = $('.reveal-video iframe').attr('src');
		var tempTitle = $('.reveal-video iframe').attr('title');
		$('.reveal-video iframe').attr('src', '');
		setTimeout(function(){ 
			$('.reveal-video iframe').attr('src', tempLink);
			$('.reveal-video iframe').attr('title', tempTitle);
		}, 500);
	});

  // Function to set focus back to the clicked button
  function setFocusBackToButton() {
    if (clickedButton) {
      clickedButton.focus();
    }
  }	

	// escape key
	$(document).on('keyup', function (evt) {
		if (evt.keyCode === 9) {
			$('.menu > li .primary-link').off().on('focusin', function(e) {
				$('.submenu').removeClass('js-dropdown-active');
				$('.is-dropdown-submenu-parent').removeClass('is-active');
			});	
		}

		if (evt.keyCode == 27) {
			$('.submenu').removeClass('js-dropdown-active');
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// role modal open
	$('.role-modal-link').on('click', function () {
		$('#role-modal').foundation('open');
		$('.role-modal-content').html($(this).attr('data-role-content'));
		$('.role-modal-title').text($(this).attr('data-role-title'));
	});

	// policy modal open
	$('.policy-modal-link').on('click', function () {
		$('#policy-modal').foundation('open');
		$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.policy-modal-title').text($(this).attr('data-apply-title'));
	});

	// Close policy modal on apply
	$('.btn-accept').on('click', function () {
		$('#policy-modal').foundation('close');
	});

	// accordions
	const accordion = new Accordions();
	accordion.init();

	// masonry grid
	jQueryBridget('masonry', Masonry, $);
	$('.grid-masonry').masonry({
		itemSelector: '.grid-item',
		percentPosition: true,
		gutter: '.gutter-sizer',
		fitWidth: true
	});

	// tabs
	$('.tabs-title a').on('click', function() {		
		var tabId = $(this).attr('data-tabs-target');
		if($(this).first().parents().eq(2).hasClass('.tab-container')){
			var tabContainer = $(this).first().parents().eq(2);
		}else {
			var tabContainer = $(this).first().parents().eq(3);
		}				
		tabContainer.find('.tab-accordion-title').removeClass('is-active');
		tabContainer.find(`.tab-accordion-title[data-tab-id='${tabId}']`).addClass('is-active');
	});	
	// tabs mobile
	$('.tab-accordion-title').on('click', function() {
		var currId = $(this).attr('data-tab-id');
		if($(this).first().parents().eq(4).hasClass('.tab-container')){
			var tabMobileContainer = $(this).first().parents().eq(4);
		}else {
			var tabMobileContainer = $(this).first().parents().eq(5);
		}	
		$(this).parent('.tabs-panel').removeClass('is-active').attr("aria-hidden","false");
		$(this).parent('.tabs-panel').siblings().removeClass('is-active').attr("aria-hidden","false");		
		tabMobileContainer.find('.tab-accordion-title').removeClass('is-active');
		$('#'+currId).addClass('is-active');
		$(this).addClass('is-active');
		tabMobileContainer.find('.tabs-title').removeClass('is-active');
		tabMobileContainer.find('.tabs-title').find(`[data-tabs-target='${currId}']`).parent('.tabs-title').addClass('is-active');
	});
	
	// tabs mobile AA
	function checkTabs(){
		if ( $(window).width() < 1023 ){
			$('.tabs-panel').attr("aria-hidden","false");
		}
	}
	checkTabs();
	$(window).resize(checkTabs);
	
	// hero video
	$('.hero-banner-pause').on('click', function() {
		var currVid = $(this).attr('data-video-id');
		if ($('#'+currVid)[0].paused) {
			$('#'+currVid)[0].play();
			$(this)[0].innerHTML = '<span class="video-icon"></span><span class="video-text">Pause</span>';
			$(this).toggleClass('video-play video-pause');
		} else {
			$('#'+currVid)[0].pause();
			$(this)[0].innerHTML = '<span class="video-icon"></span><span class="video-text">Play</span>';
			$(this).toggleClass('video-pause video-play');
		}
	});

// glide slider
const COMPONENT_NAME = "data-glide";
const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;

const components = document.querySelectorAll(COMPONENT_SELECTOR);

for (let i = 0; i < components.length; i++) {
    const options = JSON.parse(
        components[i].getAttribute(COMPONENT_NAME) || "{}"
    );
    
		const glide = new Glide(
				components[i],
				options
		);

		if (options.type == "Carousel") {
			const cloneSelector = '.glide__slide--clone';

			// Hide initial clones
			glide.on('mount.after', () => {
				const clones = components[i].querySelectorAll(cloneSelector);
				clones.forEach((clone, index) => {
						clone.style.visibility = 'hidden';
						const slideIndex = clone.getAttribute('data-slide-index');
						clone.addEventListener('focus', () => {
							// Move the glide slider to the corresponding index when a slide gains focus
							console.log('slide clone:', clone);
							console.log('slide clone index:', slideIndex);									
							glide.go('=' + slideIndex);
					});								
				});						
			});	

			glide.on('afterTransition', function () {
				// Focus on the active slide
				const activeSlide = document.querySelector('.glide__slide--active');
				activeSlide.focus();
			});				

			// Reset visibility of clones when cycling back
			glide.on('translate.jump', () => {
				// Remove the visibility styling from all clones
				components[i].querySelectorAll(cloneSelector).forEach(el => el.style.removeProperty('visibility'));
			});

		} 

		components[i].addEventListener('keyup', (e) => {
			if (e.keyCode == 39) {
					glide.go('>');
			}
			if (e.keyCode == 37) {
					glide.go('<');
			}
		});							

		// Add focus event listener to each slide
		const slides = components[i].querySelectorAll('.glide__slide');
		slides.forEach((slide, index) => {
				slide.addEventListener('focus', () => {
					// Move the glide slider to the corresponding index when a slide gains focus
					glide.go('=' + index);					
				});
		});

		glide.on('run', () => {
				// Get the current index of the active slide
				const currentIndex = glide.index;

				if (options.type == "Carousel") {
					components[i].querySelectorAll(cloneSelector).forEach(el => el.style.removeProperty('visibility'));
				};

				// Remove the active class from all slides
				slides.forEach((slide) => {
						slide.classList.remove('glide__slide--active');
				});

				// Add the active class to the slide corresponding to the active index
				slides[currentIndex].classList.add('glide__slide--active');

				// Remove the active class from all bullets
				const bullets = components[i].querySelectorAll('.glide__bullet');
				bullets.forEach((bullet) => {
						bullet.classList.remove('glide__bullet--active');
				});

				// Add the active class to the bullet corresponding to the active slide
				bullets[currentIndex].classList.add('glide__bullet--active');
		});				

		glide.mount();
    
}

	// video limit list
  var limitViewMoreButtons = document.querySelectorAll(".limitViewMoreBtn");

  limitViewMoreButtons.forEach(function(btn) {
    var limitContainerId = btn.getAttribute("data-container-id");
    var videoContainer = document.getElementById(limitContainerId);
    var items = videoContainer.querySelectorAll(".video-item");
    var batchSize = parseInt(btn.getAttribute("data-limit"));
    var currentIndex = 0;

    function hideAllItems() {
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (item) {
          item.classList.add("video-hidden");
        }
      }
    }

    function showFirstBatch() {
      for (var i = 0; i < batchSize && i < items.length; i++) {
        var item = items[i];
        if (item) {
          item.classList.remove("video-hidden");
        }
      }
      currentIndex += batchSize;
    }

    function showNextBatch() {
      for (var i = currentIndex; i < items.length && i < currentIndex + batchSize; i++) {
        var item = items[i];
        if (item) {
          item.classList.remove("video-hidden");
        }
      }

      currentIndex += batchSize;

      // Hide button if all items are shown
      if (currentIndex >= items.length) {
        btn.style.display = "none";
      }
    }

    // Initially hide all items except the first batch
    hideAllItems();

    // Show the first batch
    showFirstBatch();

    btn.addEventListener("click", showNextBatch);
  });
	

	// OT Cookie WCAG 2.2 Fix
	setTimeout(function() {
		var parentDiv = document.getElementById('onetrust-banner-sdk');
		var prefCenter = document.getElementById('onetrust-pc-sdk');

		if(parentDiv){
			var childDivs = parentDiv.querySelectorAll('div');

			childDivs.forEach(function(div) {
				div.removeAttribute('aria-describedby');
				if (div.getAttribute('role') === 'alertdialog') {
						div.removeAttribute('role');
				}
			});
		}

		if(prefCenter ){
			var prefChildDivs = prefCenter .querySelectorAll('div');

			prefChildDivs.forEach(function(div) {
				div.removeAttribute('aria-describedby');
				if (div.getAttribute('role') === 'alertdialog') {
						div.removeAttribute('role');
				}
			});
		}

	}, 500);

	// video tracking 
	var modalButtons = document.querySelectorAll('.modal-link');
	var players = {};

	modalButtons.forEach(function(button) {
			button.addEventListener('click', function() {
					var videoId = button.getAttribute('data-video-id');
					var videoLink = button.getAttribute('data-video-link');
					var modal = document.getElementById(videoId);
					var resEmbed = modal.querySelector('.responsive-embed');
					var iframe = resEmbed.querySelector('.embed-player');

					if (!iframe) {
							iframe = document.createElement('iframe');
							iframe.classList.add('embed-player');
							iframe.setAttribute('width', '100%');
							iframe.setAttribute('height', '500px');
							iframe.setAttribute('frameborder', '0');
							iframe.setAttribute('allowfullscreen', '');
							resEmbed.appendChild(iframe);
					}

					iframe.src = videoLink;

					if (players[videoId]) {
							players[videoId].destroy().then(function() {
									delete players[videoId];
									initializePlayer(videoId, iframe);
							}).catch(function(error) {
									//console.error('Error destroying player:', error);
									initializePlayer(videoId, iframe); // Try initializing anyway
							});
					} else {
							initializePlayer(videoId, iframe);
					}
			});
	});

	function initializePlayer(videoId, iframe) {
			var player = new Vimeo.Player(iframe);
			players[videoId] = player;

			player.on('play', function() {
					player.getVideoTitle().then(function(title) {
							window.parent.postMessage({
									event: 'video',
									video_action: 'play',
									video_url: iframe.src,
									video_percent: 0,
									video_title: title
							}, '*');
					});
			});

			player.on('pause', function() {
					player.getCurrentTime().then(function(time) {
							player.getDuration().then(function(duration) {
									player.getVideoTitle().then(function(title) {
											window.parent.postMessage({
													event: 'video',
													video_action: 'pause',
													video_url: iframe.src,
													video_percent: (time / duration) * 100,
													video_title: title
											}, '*');
									});
							});
					});
			});

			player.on('ended', function() {
					player.getVideoTitle().then(function(title) {
							window.parent.postMessage({
									event: 'video',
									video_action: 'finish',
									video_url: iframe.src,
									video_percent: 100,
									video_title: title
							}, '*');
					});
			});

			let lastProgressEvent = 0;

			player.on('timeupdate', function(data) {
					let percent = Math.floor(data.percent * 100);
					if (percent >= 25 && percent < 50 && lastProgressEvent < 25) {
							lastProgressEvent = 25;
					} else if (percent >= 50 && percent < 75 && lastProgressEvent < 50) {
							lastProgressEvent = 50;
					} else if (percent >= 75 && percent < 100 && lastProgressEvent < 75) {
							lastProgressEvent = 75;
					} else if (percent === 100 && lastProgressEvent < 100) {
							lastProgressEvent = 100;
					} else {
							return;
					}

					player.getVideoTitle().then(function(title) {
							window.parent.postMessage({
									event: 'video',
									video_action: 'playProgress',
									video_url: iframe.src,
									video_percent: percent,
									video_title: title
							}, '*');
					});
			});
	}

	document.querySelectorAll('.close-button').forEach(function(closeButton) {
			closeButton.addEventListener('click', function() {
					var modal = closeButton.closest('.reveal');
					var resEmbed = modal.querySelector('.responsive-embed');
					var iframe = resEmbed.querySelector('.embed-player');
					var videoId = modal.getAttribute('id');

					if (players[videoId]) {
							players[videoId].destroy().then(function() {
									delete players[videoId];
									if (iframe && resEmbed.contains(iframe)) {
											resEmbed.removeChild(iframe);
									}
							}).catch(function(error) {
									//console.error('Error destroying player on close:', error);
									if (iframe && resEmbed.contains(iframe)) {
											resEmbed.removeChild(iframe);
									}
							});
					}

					if (iframe) {
							iframe.src = '';
					}
			});
	});

});

window.addEventListener('message', function(event) {
	var data = event.data;

	if (typeof data === 'object' && data.event === 'video') {
			//console.log('Pushing to dataLayer:', data);
			dataLayer.push({
					event: data.event,
					video_action: data.video_action,
					video_url: data.video_url,
					video_percent: data.video_percent,
					video_title: data.video_title
			});
	} else {
			//console.log('Data event is not video:', data.event);
	}
}, false);